<template>
  <div class="flex space-x-2 items-center">
    <label :for="inputId" class="flex text-sm whitespace-nowrap text-modalGray flex-1 items-center">
      {{ label }}
      <i class="fas fa-search mx-2 text-primary text-lg" role="img" aria-label="Search icon for search bar" />
      <input
        :id="inputId"
        ref="input"
        v-model="inputValue"
        :name="inputName"
        :placeholder="placeholder"
        class="px-3 w-full text-lg focus:border-transparent outline-none placeholder-modalGray"
        role="combobox"
        aria-autocomplete="list"
        :aria-controls="inputAriaControl"
        :aria-expanded="expanded"
        :aria-live="ariaLiveStatus"
        data-test-id="search-modal-input"
        @keydown="keydown"
      >
    </label>
    <button
      :class="[
        'border border-gray-200 bg-gray-100 text-modalGray rounded-md py-2 px-3 text-sm',
        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
      ]"
      type="button"
      aria-label="escape from search dialog"
      @click="close"
    >
      esc
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import { func, string } from 'vue-types';

type AriaLiveStatus = 'off' | 'assertive' | 'polite';

export default defineComponent({
  props: {
    label: string(),
    modelValue: string(),
    placeholder: string(),
    expanded: Boolean,
    close: func().isRequired,
    keydown: func<(event: KeyboardEvent) => void>().isRequired,
    inputId: string(),
    inputAriaControl: string(),
    inputName: string(),
    ariaLiveStatus: {
      type: String as PropType<AriaLiveStatus>,
      default: 'off' as AriaLiveStatus, // Set a default value and explicitly cast it to AriaLiveStatus
    },
  },

  emits: [
    'update:modelValue',
  ],

  setup(properties, { emit }) {
    const inputValue = computed({
      get: () => properties.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return {
      inputValue,
    };
  },
});
</script>
