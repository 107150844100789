<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <label
    :class="[
      {
        'has-error': errors.length,
        success: meta.valid,
      },
      'block',
      getContainerClass(),
      $props.class,
    ]"
    :for="$props.for"
  >
    <slot name="label">
      <div v-if="label" :class="['flex space-x-1', getLabelClass()]">
        <span>{{ label }}</span>
        <span v-if="required" :class="requiredClass">*</span>
      </div>
    </slot>
    <div :class="[style.element, elementClass]">
      <slot
        v-bind="{
          required,
          name: field.name,
        }"
      />
      <p
        v-if="errors.length"
        :id="errorId"
        :class="errorsClass"
        aria-live="assertive"
      >
        {{ errors.join(', ') }}
      </p>
      <slot name="successMessage">
        <p
          v-if="meta.valid && successMessage"
          :class="successClass"
        >
          {{ successMessage }}
        </p>
      </slot>
    </div>
  </label>
</template>

<script lang="ts">
import { FieldContext } from 'vee-validate';
import { computed, defineComponent } from 'vue';
import {
  any, bool, object, oneOf, string,
} from 'vue-types';

import { ClassesProperties, getStyles, layoutValues } from './formItemStyles';

export default defineComponent({
  name: 'FormItem',
  props: {
    field: object<FieldContext>().isRequired,
    label: string(),
    required: bool(),
    requiredClass: any().def('text-red-700'),
    labelClass: any(),
    elementClass: any(),
    class: any(),
    layout: oneOf(layoutValues).def(layoutValues[0]),
    classes: object<ClassesProperties>(),
    replaceContainerClass: any().def(undefined),
    successMessage: string(),
    successClass: any().def('text-ts text-green-600'),
    errorsClass: any().def('text-sm text-red-600'),
    id: string(),
    errorId: string(),
    for: string(),
  },
  setup(properties) {
    return {
      style: getStyles(properties.layout, properties.classes),
      errors: computed(() => properties.field.errors.value),
      meta: computed(() => properties.field.meta),
    };
  },
  methods: {
    getLabelClass() {
      if (this.labelClass) {
        return this.labelClass;
      }
      return this.style.label;
    },
    getContainerClass() {
      if (this.replaceContainerClass) {
        return this.replaceContainerClass;
      }
      return this.style.container;
    },
  },
});
</script>
